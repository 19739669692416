import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import '../components/all.sass';

// based on design of filmchain app
const ErrorPage = () => (
  <Wrapper>
    <LogoContainer></LogoContainer>
    <Image src={'/img/bgError.jpg'} />
    <ErrorCode>
      <span>ERROR 404</span>
    </ErrorCode>
    <ErrorText>
      <span>
        We couldn't find <br /> that page.
      </span>
    </ErrorText>
    <RedirectText>
      <span>
        {' '}
        Return to <StyledLink to="/">homepage</StyledLink>
      </span>
    </RedirectText>
  </Wrapper>
);

export default ErrorPage;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  font-family: 'Eina';
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* padding-top: 30vh; */
  margin: 0;
  padding-left: 35%;
  justify-items: center;
  /* margin-left: 30%; */
  flex-wrap: none;
  overflow: hidden;
  overflow-y: hidden;

  /* margin-right: 30%; */
`;

const ErrorCode = styled.div`
  color: #db6e6b;
  margin-bottom: 40px;
`;

const ErrorText = styled.div`
  span {
    font-size: 64px;
    line-height: 72px;
  }
`;

const RedirectText = styled.div`
  margin-top: 80px;
  color: #a6a5ac;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  color: #fff;
  text-decoration: underline;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`;

const LogoContainer = styled.div`
  cursor: pointer;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  align-self: center;
  padding-top: 6%;
  padding-left: 4%;
  /* margin-left: 0.9375em; */
`;
